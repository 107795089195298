import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { toast } from "react-toastify";
import { httpClient } from "../../lib/HttpClient";
import t from "../../lib/localization";
import LayoutWithSidebar from "../../components/LayoutWithSidebar";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import AddLinkIcon from '@mui/icons-material/AddLink';
import SeverityPill from '../../components/SeverityPill';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    }
}));

const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
);

CustomToolbar.propTypes = {
    setFilterButtonEl: PropTypes.func.isRequired,
};

export default function PaymentSessionsList() {

    const classes = useStyles();
    const [payments, setPayments] = useState([]);
    
    const [filterButtonEl, setFilterButtonEl] = React.useState(null);

    const columns = [
        // { field: 'id', headerName: 'ID', hide: true },
        {
            field: 'title',
            headerName: t('payment.paymentTitle'),
            width: 110,
            editable: false,
        },
        {
            field: 'txtId',
            headerName: t('payment.transactionDetails'),
            width: 120,
            renderCell: (cellValues) => {
                return (cellValues.row.txId &&
                    <IconButton onClick={() => copyLink(cellValues.row)} aria-label="delete" color="primary">
                        <AddLinkIcon />
                    </IconButton>
                );
            },
            editable: false,
        },
        {
            field: 'payoutAddress',
            headerName: t('payment.payoutAddress'),
            width: 300,
            editable: false,
        },
        {
            field: 'amount',
            headerName: t('payment.amount'),
            width: 150,
            typr: 'number',
            editable: false,
        },
        {
            field: 'sessionId',
            headerName: t('payment.paymentId'),
            width: 300,
            editable: false,
        },
        // {
        //     field: 'tokenToPay',
        //     headerName: t('payment.token'),
        //     width: 150,
        //     editable: false,
        // },
        {
            field: 'status',
            headerName: t('payment.status'),
            width: 150,
            renderCell: (cellValues) => {
                return (
                    cellValues.row && <SeverityPill
                        color={(cellValues?.row?.status === 'WaitingToReceive' && 'secondary')
                            || (cellValues?.row?.status === 'Done' && 'success')
                            || (cellValues?.row?.status === 'Expired' && 'error')
                            || (cellValues?.row?.status === 'Failed' && 'error')
                            || (cellValues?.row?.status === 'Declined' && 'error')
                            || 'warning'}
                    >
                        {(cellValues?.row?.status === 'WaitingToReceive' || cellValues?.row?.status === 'Done' || cellValues.row.status === 'Expired' || cellValues.row.status === 'Failed' || cellValues.row.status === 'Declined') ? t(cellValues.row.status) : t("InProgress")}
                    </SeverityPill>
                );
            },
            editable: false,
        }
    ];

    useEffectAsync(async () => {
        try {
            var payments = await httpClient.Get("/api/payment/sessions");
            setPayments(payments);
        } catch (error) {

        }

    }, [])

    const copyLink = (item) => {
        navigator.clipboard.writeText(`https://tronscan.org/#/transaction/${item.txId}`);
        toast.success(t("payment.txIdCopied"));
    }


    return (
        <LayoutWithSidebar>
            <div style={{ height: 550, width: '100%' }}>
                <DataGrid
                    disableColumnMenu
                    columns={columns}
                    rows={payments}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            anchorEl: filterButtonEl,
                        },
                        toolbar: {
                            setFilterButtonEl,
                        },
                    }}
                />
            </div>
        </LayoutWithSidebar >
    )

}