import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Alert, Box, Card, CardContent, Chip, Container, Fade, Grid, IconButton, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from 'react';
import CopyToMemory from "../../components/CopyToClipboard";
import FaildPayment from '../../components/Payment/FailedPayment';
import PaymentProgress from "../../components/Payment/PaymentProgress";
import RecipientInfo from "../../components/Payment/RecipientInfo";
import SuccessfulPayment from "../../components/Payment/SuccessfulPayment";
import Timer from "../../components/Timer";
import t, { useLocalization } from "../../lib/localization";
import TrasnferFeeCaution from './TransferFeeCaution';
var QRCode = require('qrcode.react');

const useStyle = makeStyles({
    root: {
        borderRadius: '10px !important',
        border: '1px solid orange !important',
        backgroundColor: '#fff6ea !important'
    },
    icon: { color: 'orange !important', marginRight: '5px', marginBottom: '10px', fontSize: '36px !important' },
    button: { background: 'orange', color: 'white', width: '200px', borderRadius: '10px' }
})

export default function PaymentInfo({ item, declineButton }) {

    const classes = useStyle();
    //payment caution
    const [caution, setCaution] = useState(true);
    const { language, setLanguage } = useLocalization();

    useEffect(() => {
        if (!!item) {
            setLanguage(item.paymentPageDefaultLanguage)
        }
    }, [item?.paymentPageDefaultLanguage])

    const shouldShowPaymentInfo = !caution && (item?.statusExternal < 3 || item?.remainedTimeInMinute > 0);
    return (
        <Container sx={{ marginTop: '30px' }} maxWidth="sm">
            <Grid container margin='10px'>

                <Typography textAlign='center' variant='h6'>
                    {t("payment.headerText")} <Link href="https://www.cara-way.com">{t("payment.caraWay")}</Link>
                </Typography>
            </Grid>
            {item && <Card elevation={4} >
                <CardContent>
                    <Grid container justifyContent="space-around" >
                        {

                            shouldShowPaymentInfo ?
                                <>
                                    <Grid item justifyContent='center' margin='20px'>
                                        <Alert classes={{
                                            icon: classes.icon,
                                            root: classes.root
                                        }} sx={{ width: '100%' }} variant="outlined" severity="info">
                                            {t('pleasOnlyPayTether')}
                                            <IconButton onClick={() => setCaution(true)}>
                                                <KeyboardArrowDownIcon />
                                            </IconButton>
                                        </Alert>
                                    </Grid>

                                    <Grid item justifyContent='center'>
                                        <QRCode
                                            value={item.payinAddress}
                                            size={200}
                                            bgColor={"#ffffff"}
                                            fgColor={"#0000000"}
                                            level={"M"}
                                            includeMargin={false}
                                            renderAs={"svg"}
                                        />

                                    </Grid>
                                    <Grid item justifyContent='center'>
                                        <Grid sx={{ margin: '10px' }} container color='#0072ff' justifyContent='center' >
                                            <Typography color='error'>
                                                {t("payment.qrCodeText")}
                                            </Typography>
                                        </Grid>
                                        <Grid container  >
                                            <CopyToMemory text={item.payinAddress}>
                                                <Typography variant="h6">
                                                    <Box
                                                        component="button"
                                                        fontFamily="inherit"
                                                        fontSize="18px"
                                                        fontWeight="400"
                                                        lineHeight="1.25"
                                                        display="inline-block"
                                                        width="100%"
                                                        margin=".5rem 0"
                                                        padding="24px"
                                                        textAlign="left"
                                                        sx={{ cursor: 'pointer' }}
                                                        border="0"
                                                        borderRadius="4px"
                                                        data-clipboard-text="album-2"
                                                        type="button"
                                                    >
                                                        {item.payinAddress}
                                                    </Box>

                                                </Typography>
                                            </CopyToMemory>

                                        </Grid>
                                    </Grid>
                                </> :
                                item.statusExternal > 9 ? <FaildPayment /> :
                                    item.statusExternal === 9 ?
                                        <>
                                            <SuccessfulPayment />
                                            <Grid container justifyContent='center'>
                                                <Typography fontWeight={700} variant='h6'>{t("payment.paymentId")}:</Typography>
                                                &nbsp;&nbsp;
                                            </Grid>
                                            <Grid container justifyContent='center'>
                                                <CopyToMemory text={item.sessionId}>
                                                    <Chip sx={{ borderRadius: '10px', borderStyle: 'dashed', borderWidth: '1px' }} label={`${item.sessionId.toUpperCase()}`} />
                                                </CopyToMemory>
                                            </Grid>
                                        </>

                                        : <TrasnferFeeCaution setCaution={setCaution} item={item} />


                        }
                        <Grid item justifyContent='center'>
                            <Grid container margin='10px' justifyContent='center'>
                                <CopyToMemory text={Number(item.amount)}>
                                    <IconButton>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </CopyToMemory>
                                <Typography variant="h4" component="div">
                                    {Number(item.amount).toFixed(3)}
                                </Typography>
                                &nbsp;
                                <Typography variant="h6" component="div">
                                    USDT
                                </Typography>

                            </Grid>
                            <Grid container justifyContent='center'>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant='h6'>
                                    {item.title}
                                </Typography>

                            </Grid>
                        </Grid>
                        {
                            item.amountReceived > 0 &&
                            <Grid container justifyContent='center'>
                                <Typography component='p' sx={{ mb: 1.5 }} color="text.secondary">
                                    {t('sharedReceived')} : {item.amountReceived} {t("usdt")}
                                </Typography>

                            </Grid>
                        }
                        <PaymentProgress session={item} />
                        <Grid container sx={{ margin: '10px' }} justifyContent='center'>

                        </Grid>
                        <Grid container justifyContent='left'>
                            <RecipientInfo recipient={item.recipient} />
                            {
                                item.remainedTimeInMinute > 0 && item.statusExternal < 3 ?
                                    <Timer initial={item.deadlineInMinute * 60} passed={item.remainedTimeInMinute} /> : ""
                            }
                        </Grid>
                    </Grid>
                    {
                        !!declineButton && item.statusExternal <= 2 &&
                        declineButton
                    }
                </CardContent>
            </Card>}
        </Container>
    )
}

