import { Button, Container, FormControl } from '@mui/material';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEffectAsync from "../lib/helper/useEffectAsync";
import { httpClient } from "../lib/HttpClient";
import { makeStyles } from "@mui/styles";
import { toast } from 'react-toastify';
import t from '../lib/localization';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
            flexBasis: '33%',
        },
    },
}));

export default function EditViewLayout({
    children, formik, endpoint, isFormData,
    onSave
}) {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const handleSave = async () => {
        if (formik.isValid) {
            try {
                var response = null;
                if (id) {
                    response = await httpClient.Put(`/api/${endpoint}`, formik.values, isFormData);
                } else
                    response = await httpClient.Post(`/api/${endpoint}`, formik.values, isFormData);

                toast.success(t("operationSuccuss"))
                onSave && onSave();
                history.goBack()
            } catch (error) {
                toast.error(error.toString())
            }
        }
    }

    useEffectAsync(async () => {
        if (id) {
            const response = await httpClient.Get(`/api/${endpoint}/${id}`);
            formik.setValues(response);
        }
    }, [id])

    return (
        <Container maxWidth="xs" className={classes.container}>
            {children}
            <FormControl fullWidth margin="normal">
                <div className={classes.buttons}>
                    <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
                        {t('Cancle')}
                    </Button>
                    <Button type="button" color="success" variant="contained" onClick={handleSave}>
                        {t('Save')}
                    </Button>
                </div>
            </FormControl>
        </Container>
    )
}