import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import t from '../lib/localization';
import '../assets/css/home.css';

const Header = () => {

    const history = useHistory();
    const authenticated = useSelector((state) => !!state.session.user);

    const login = () => {
        history.push('/login');
    }

    const register = () => {
        history.push('/register');
    }

    let buttons;
    if (!authenticated) {
        buttons = <form className="d-flex">
            <button className="btn btn-outline-primary me-2" type="button" onClick={login}>{t('loginLogin')}</button>
            <button className="btn btn-primary text-white me-2" type="button" onClick={register}>{t('loginRegister')}</button>
        </form>
    }

    return (
        <header className="homepage">
            <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "#ebedef" }}>
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarContent" aria-controls="navbarContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav mb-2 mb-lg-0 me-auto">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/"> {t('landing')}</a>
                            </li>
                            <li className="nav-item ml-3">
                                <a className="nav-link" target="_self" href="/plugins"> {t('landing.plugin')} </a>
                            </li>
                        </ul>
                        {buttons}
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;