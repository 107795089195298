import { ClickAwayListener, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => ({
    tooltip:{
        fontSize :'15px !important'
    }
}))

export default function WithHelpLayout({ children, help }) {
    const [open, setOpen] = useState(false);
    const classes = useStyle();
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        !!help ?
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={10}>
                    {
                        children
                    }
                </Grid>
                <Grid item sx={2} justifyItems='center' >
                    <div>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                                components='pre'
                                title={help}
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                classes={{tooltip:classes.tooltip}}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                placement="left-start">
                                <IconButton
                                    onClick={handleTooltipOpen}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </div>
                </Grid>
            </Grid>
            :
            children
    )
}