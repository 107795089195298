import { useEffect } from "react"


export default function useEffectAsync(effect, dependency, cleanup) {
    
    useEffect(() => {
        effect()
        return () => {
            cleanup && cleanup()
        }
    }, dependency)
}