import React from 'react';
import * as Yup from 'yup';
import { Grid, Button, Card, Typography, Link, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from "@mui/styles"
import { useFormik } from 'formik';
import { httpClient } from '../../lib/HttpClient';
import t from '../../lib/localization';
import StartPage from '../StartPage';
import TextInput from '../../components/TextInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PasswordTextInput from '../../components/PasswordTextInput';

const useStyles = makeStyles((theme) => ({
  register: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  link: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
  li: {
    listStyle: 'none'
  }
}));

const RegisterForm = () => {
  const classes = useStyles();
  const history = useHistory();

  var schema = Yup.object({
    name: Yup.string().required(t("loginRequired")).max(500, t("loginNameLength")),
    email: Yup.string().required(t("loginRequired")),
    password: Yup.string().min(8, t("register.passwordMin"))
      .matches(/\d/, t("register.passwordDigit"))
      .matches(/[a-z]/, t("register.passwordLowerCase"))
      .matches(/[*@!#%&()^~{}]+/, t("register.passwordCharacters"))
      .matches(/[A-Z]/, t("register.passwordUperCase"))
      .required(t("loginRequired")),
    confirmPassword: Yup.string().required(t("loginRequired"))
  })

  var formik = useFormik({

    validationSchema: schema,
    initialErrors: false,
    initialValues: {}
  })

  const handleRegister = async () => {
    try {
      const response = await httpClient.Post("api/user/register", formik.values);
      window.location.href = "/home"
    } catch (error) {
      toast.error(error.toString());
    }

  };

  return (
    <StartPage>
      <Card elevation={12}>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid container item>
              <Grid item>
                <Typography className={classes.link} color="primary">
                  <Link onClick={() => history.push('/login')}>
                    <ArrowBackIcon />
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography className={classes.register} color="primary">
                  {t('loginRegister')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <TextInput
                label={t('sharedName')}
                formik={formik} name="name" />

            </Grid>
            <Grid item>
              <TextInput formik={formik}
                label={t('userEmail')}
                name="email" />
            </Grid>
            <Grid item>
              <PasswordTextInput
                label={t('userPassword')}
                formik={formik} name="password" />
            </Grid>
            <Grid item>
              <PasswordTextInput
                label={t('userPasswordConfirm')}
                formik={formik} name="confirmPassword" />
            </Grid>
            {/* <Grid item>
              <ul>
                <li ><Typography color={formik.values.password?.length >= 8 ? 'green' : 'error'} variant='caption'>Password minimum length 8</Typography></li>
                <li><Typography color={/\d/.test(formik.values.password) ? 'green' : 'error'} variant='caption'>Password requires number</Typography></li>
                <li><Typography color={/[a-z]/.test(formik.values.password) ? 'green' : 'error'} variant='caption'>Password requires lower case</Typography></li>
                <li><Typography color={/[A-Z]/.test(formik.values.password) ? 'green' : 'error'} variant='caption'>Password requires upper case</Typography></li>
                <li><Typography color={/[*@!#%&()^~{}]+/.test(formik.values.password) ? 'green' : 'error'} variant='caption'>Password requires special characters</Typography></li>
                <li><Typography color={formik.values.password===formik.values.confirmPassword ? 'green' : 'error'} variant='caption'>Password doesn't match</Typography></li>
              </ul>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleRegister}
                disabled={!formik.isValid}
                fullWidth
              >
                {t('loginRegister')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StartPage>
  );
};

export default RegisterForm;
