import * as Yup from 'yup';
import { useFormik } from "formik";
import { CardContent, Card, Grid } from "@mui/material";
import t from "../lib/localization";
import TextInput from "../components/TextInput";
import EditViewLayout from "../components/EditViewLayout";

export default function RecipientNew() {

    var schema = Yup.object({
        name: Yup.string().required(t("recipientNew.mandatory")).max(500, t("recipientNew.nameLength")),
        email: Yup.string().email(t("recipientNew.validEmail"))
    })

    var formik = useFormik({

        validationSchema: schema,
        initialErrors: false,
        initialValues: {}
    })
    return (
        <>
            <EditViewLayout endpoint="recipient" formik={formik} >
                <Card elevation={3}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextInput formik={formik} name="name" />
                            </Grid>
                            <Grid item>
                                <TextInput formik={formik} name="email" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </EditViewLayout>
        </>
    )
}