import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from '../../components/Common/ImageUploader';
import WithHelpLayout from '../../components/Common/WithHelpLayout';
import EditViewLayout from '../../components/EditViewLayout';
import LayoutWithSidebar from '../../components/LayoutWithSidebar';
import RemoveDialog from '../../components/RemoveDialog';
import TextInput from '../../components/TextInput';
import useEffectAsync from '../../lib/helper/useEffectAsync';
import { httpClient } from '../../lib/HttpClient';
import t from '../../lib/localization';
import { sessionActions } from '../../store';

const useStyles = makeStyles((theme) => ({

    pickerWrapper: {
        background: '#EFEFE3',
        width: 100,
        height: 100,
        marginLeft: -8,
        "& svg": {
            fontSize: 28
        }
    }
}));

export default function Profile() {
    const user = useSelector((state) => state.session.user);
    const [photos, setPhotos] = useState([])
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const dispatch = useDispatch();
    const handleSelectPhoto = (files) => {
        setPhotos(files);
    }

    const handleRemoveResult = (result) => {
        if (result) {

            formik.setFieldValue("medias", formik.values.medias.filter(c => c.id != deleteId));
        }
        setRemoveDialogShown(false);
    }

    useEffectAsync(async () => {
        formik.setValues(user.recipient)
    }, [user])

    useEffect(() => {

        var i = 0;
        if (photos) {
            for (const photo of photos) {
                const imageUrl = window.URL.createObjectURL(photo);

                formik.setFieldValue(`file${i}`, photos[i]);

                i++;
            }
            // formik.setFieldValue('files', [...photos]);
        }
    }, [photos])

    var schema = Yup.object({
        name: Yup.string().required(t("shared.mandatory")).max(30, t("shared.titleLength")),
        email: Yup.string().email(t("profile.enterValidEmail")).required(t("shared.mandatory")).max(30, t("shared.titleLength")),
        webSiteUrl: Yup.string().nullable()
            .matches(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                t("profile.enterValidUrl"))
    })

    var formik = useFormik({


        validationSchema: schema,
        initialErrors: false,
        initialValues: { medias: [] }
    })

    const deletePicture = async (id) => {
        setRemoveDialogShown(true);
        setDeleteId(id);
    }
    const handleSave = async () => {
        var response = await httpClient.Get('api/user');
        dispatch(sessionActions.updateUser(response));
    }

    return (
        <LayoutWithSidebar>
            <EditViewLayout onSave={handleSave} isFormData endpoint="recipient" formik={formik} >
                <Card elevation={2}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextInput formik={formik} label={t("profile.email")} name="email" />
                            </Grid>

                            <Grid item>
                                <TextInput formik={formik} label={t("profile.name")} name="name" />
                            </Grid>

                            <Grid item>
                                <TextInput formik={formik} label={t("profile.webSiteUrl")} name="webSiteUrl" />
                            </Grid>
                            {formik.values?.medias.length > 0 ?
                                <Grid item sx={{ width: '100%' }}>
                                    <ImageList cols={3} rowHeight='120' >
                                        {formik.values?.medias.map((it) => (
                                            <ImageListItem key={it.img}>
                                                <img
                                                    src={`${it.path}`}
                                                    srcSet={`${it.path}`}
                                                    alt={it.name}
                                                    loading="lazy"
                                                />
                                                <ImageListItemBar
                                                    title={it.name}
                                                    // subtitle={item.author}
                                                    actionIcon={
                                                        <IconButton
                                                            onClick={() => deletePicture(it.id)}
                                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                            aria-label={`info about ${it.title}`}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>

                                        ))}
                                    </ImageList>
                                </Grid> :
                                ""
                            }

                            <Grid item>
                                <WithHelpLayout help={t('profile.logoHelp')}>
                                    <Typography className='s20'>
                                        {t("profile.logo")}
                                    </Typography>
                                    <ImageUploader defaultImage={formik.values.medias.length > 0 ? formik.values.medias[0].path : ""}
                                        receiveFiles={handleSelectPhoto} />
                                </WithHelpLayout>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <RemoveDialog open={removeDialogShown} endpoint={"payment/picture"} itemId={deleteId} onResult={handleRemoveResult} />

            </EditViewLayout>
        </LayoutWithSidebar>
    )
}

const PickerComponent = ({ user }) => {
    return (
        <Grid container justifyContent='center' >
            <Grid item xs={12}>
                <Grid container sx={{
                    borderRadius: '50px',
                    backgroundImage: `url(${user.medias.length > 0 ? user.medias[0].path : ""})`,
                    width: 100,
                    height: 100
                }}
                ></Grid>
            </Grid>
            <Grid item xs={12}>

            </Grid>
        </Grid>
    )
}