import { IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditCollectionView from "../../components/EditCollectionView";
import LayoutWithSidebar from "../../components/LayoutWithSidebar";
import t from "../../lib/localization";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useEffectAsync from "../../lib/helper/useEffectAsync";
import { httpClient } from "../../lib/HttpClient";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import AddLinkIcon from '@mui/icons-material/AddLink';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));

function PaymentsView({ updateTimestamp, onMenuClick }) {
    const [items, setItems] = useState([]);
    useEffectAsync(async () => {
        const items = await httpClient.Get("/api/payment");
        setItems(items);
    }, [updateTimestamp])
    const classes = useStyles();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.columnAction} />
                        <TableCell>{t('paymentTitle')}</TableCell>
                        <TableCell>{t('paymentAmount')}</TableCell>
                        <TableCell>{t('payment.Link')}</TableCell>
                        <TableCell>{t('payment.instantPayout')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell className={classes.columnAction} padding="none">
                                <IconButton onClick={(event) => onMenuClick(event.currentTarget, item)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => copyLink(item)} aria-label="delete" color="primary">
                                    <AddLinkIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>{item.instantPayout ? <DoneIcon /> : <CloseIcon />}</TableCell>
                           
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const copyLink = (item) => {
    navigator.clipboard.writeText(`${window.location.origin}/payment/create/${item.id}`);
    toast.success(t("payment.paymentLinkCopied"));
}

const menuItems = (item, menuHide) => [
    <MenuItem
        onClick={() => {
            copyLink(item);
            menuHide();
        }} >{t('payment.copyPaymentLink')}</MenuItem>,
]



const PaymentsPage = () => (
    <LayoutWithSidebar>
        <EditCollectionView menuItems={menuItems} content={PaymentsView} editPath="/payment" endpoint="payment" />
    </LayoutWithSidebar>
);

export default PaymentsPage;
