import * as Yup from 'yup'
import { Card, CardContent, Grid } from "@mui/material";
import { useFormik } from "formik";
import LayoutWithSidebar from "../components/LayoutWithSidebar";
import EditViewLayout from "../components/EditViewLayout";
import SelectField from "../components/SelectInput";
import TextInput from "../components/TextInput";
import t from "../lib/localization";
const TronWeb = require('tronweb');

export default function AccountNew() {

    var schema = Yup.object({
        name: Yup.string().required(t("account.mandatory")).max(50, t("account.nameLength")),
        address: Yup.string().test("tronAddress", t("account.invalidAddress"), (value, context) => {
            const MainNet = "https://api.trongrid.io";
            const HttpProvider = TronWeb.providers.HttpProvider;
            const fullNode = new HttpProvider(MainNet);
            const solidityNode = new HttpProvider(MainNet);
            const eventServer = new HttpProvider(MainNet);
            const tronweb = new TronWeb(fullNode, solidityNode, eventServer);
            return tronweb.isAddress(value);
        }).required(t("account.mandatory")),

        tokenId: Yup.string().required(t("account.mandatory")),
    })

    var formik = useFormik({
        validationSchema: schema,
        initialErrors: false,
        initialValues: {}
    })

    return (
        <LayoutWithSidebar>
            <EditViewLayout endpoint="account" formik={formik} >
                <Card elevation={3}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextInput formik={formik} label={t("accountName")} name="name" />
                            </Grid>
                            <Grid item>
                                <TextInput formik={formik} label={t("accountAddress")} name="address" />
                            </Grid>
                            <Grid item>
                                <SelectField
                                    margin="normal"
                                    formik={formik}
                                    endpoint="/api/token"
                                    label={t("accountToken")}
                                    name="tokenId"
                                    emptyValue={null}
                                    variant="filled"
                                />
                                {/* <SelectField
                                    margin="normal"
                                    formik={formik}
                                    emptyValue={null}
                                    endpoint="/recipient"
                                    name="recipientId"
                                    label="پذیرنده"
                                    variant="filled"
                                /> */}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </EditViewLayout>
        </LayoutWithSidebar>
    )
}