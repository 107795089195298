const traccarWhite = '#FFF';
const CryptoBlue = '#dae9eb';
const CryptoGreen = '#1aa9b5';
const traccarRed = '#CC2222';
const traccarGray = '#888888';

export default {
  common: {
    blue: CryptoBlue,
    green: CryptoGreen,
    red: traccarRed,
    gray: traccarGray,
  },
  primary: {
    main: CryptoGreen,
  },
  secondary: {
    main: CryptoGreen,
    contrastText: traccarWhite,
  },
  colors: {
    red: {
      color: traccarRed,
    },
    green: {
      color: CryptoGreen,
    },
    gray: {
      color: traccarGray,
    },
  },
};
