import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditCollectionView from "../../components/EditCollectionView";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useEffectAsync from "../../lib/helper/useEffectAsync";
import { httpClient } from "../../lib/HttpClient";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import CopyToMemory from "../CopyToClipboard";

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));
function ApiKeysView({ updateTimestamp, onMenuClick }) {
    const [items, setItems] = useState([]);
    useEffectAsync(async () => {
        const items = await httpClient.Get("api/user/apikey");
        setItems(items);
    }, [updateTimestamp])


    const classes = useStyles();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.columnAction} />
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell className={classes.columnAction} padding="none">
                                <IconButton onClick={(event) => onMenuClick(event.currentTarget, item)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <CopyToMemory text={item.value}>
                                    <Box
                                        component="button"
                                        fontFamily="inherit"
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="1.25"
                                        display="inline-block"
                                        margin=".5rem 0"
                                        padding="5px"
                                        textAlign="left"
                                        sx={{ cursor: 'pointer', textAlign: 'center' }}
                                        border="0"
                                        borderRadius="4px"
                                        data-clipboard-text="album-2"
                                        type="button"
                                    >
                                        {item.value}
                                    </Box>
                                </CopyToMemory>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
const ApiKeysTable = () => (
    <EditCollectionView disableAdd content={ApiKeysView} endpoint="user\apikey" />
);

export default ApiKeysTable;
