import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { httpClient } from "../../lib/HttpClient";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import t from "../../lib/localization";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useEffectAsync from "../../lib/helper/useEffectAsync";
import WithdrawDialog from '../../components/WithdrawDialog';
import EditCollectionView from "../../components/EditCollectionView";
import LayoutWithSidebar from "../../components/LayoutWithSidebar";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));
function AccountsView({ updateTimestamp, onMenuClick }) {
    const [items, setItems] = useState([]);
    const [itemId, setItemId] = useState();
    const [widthrawDialogShown, setWidthrawDialogShown] = useState(false);

    useEffectAsync(async () => {
        const items = await httpClient.Get("/api/recipient/accounts");
        setItems(items);
    }, [updateTimestamp])

    const withdraw = async (account) => {
        setWidthrawDialogShown(true);
        setItemId(account.id);
    }

    const handleWidthrawResult = async (result) => {
        setWidthrawDialogShown(false);
        if (result) {
            const items = await httpClient.Get("/api/recipient/accounts");
            setItems(items);
            toast.success(t("account.successfulWithdraw"))
        }
    }

    const classes = useStyles();
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.columnAction} />
                            <TableCell>{t('sharedName')}</TableCell>
                            <TableCell>{t('accountAddress')}</TableCell>
                            <TableCell>{t('accountBalance')}</TableCell>
                            <TableCell>{t('account.withdraw')}</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell className={classes.columnAction} padding="none">
                                    <IconButton onClick={(event) => onMenuClick(event.currentTarget, item)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.address}</TableCell>
                                <TableCell>{item.balance}</TableCell>
                                <TableCell>
                                    {
                                        item.balance >= 3 ?
                                            <IconButton onClick={() => withdraw(item)} aria-label="delete" color="primary">
                                                <PublishedWithChangesIcon />
                                            </IconButton> :
                                            <Tooltip
                                                placement="top"
                                                title={t("account.zeroBalanceTooltip")}>
                                                <div>
                                                    <IconButton disabled aria-label="delete" color="primary">
                                                        <PublishedWithChangesIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>

                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <WithdrawDialog open={widthrawDialogShown} endpoint={"account/withdraw"} itemId={itemId} onResult={handleWidthrawResult} />
        </>
    )
}
const AccountsPage = () => (
    <LayoutWithSidebar>
        <EditCollectionView content={AccountsView} editPath="/account" endpoint="account" />
    </LayoutWithSidebar>
);

export default AccountsPage;
