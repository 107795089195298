import React from "react";
import { Box, Button, Card, CardActions, CardContent, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { httpClient } from "../../lib/HttpClient";
import { useHistory, useParams } from "react-router";
import Payment from "../../assets/images/payment.png";
import SwipeableTextMobileStepper from "../../components/Common/SwipableView";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import t from "../../lib/localization";

const useStyle = makeStyles({
    image: {
        objectFit: 'contain',
        width: '100%',

        height: 335,
    },
    root: {
        background: '#EFEFE3',
        "& div": {
            marginTop: 0,
            marginBottom: 0
        }
    },
    divImage: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 0,
    }
})

export default function CreatePaymentPage() {
    const [data, setData] = React.useState();
    const [medias, setMedias] = React.useState([]);
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyle();

    const slides = medias.map(m =>
        <img layout='fill' src={`${m.path}`} draggable="false" />
    )
    const createPayment = async () => {
        const payment = await httpClient.Get(`/api/payment/${id}`)
        setData(payment);
        setMedias(payment.medias);
    }

    useEffectAsync(createPayment, [])

    const payment = async (event) => {
        event.preventDefault();
        const { sessionId } = await httpClient.Post(`/api/payment/session/${id}`)
        if (sessionId) {
            history.push(`/payment/pay/${sessionId}`)
        }
    }

    return (
        <Container sx={{ marginTop: '30px' }} maxWidth="sm">
            {data && <Card elevation={3}>
                <CardContent>
                    <Grid container justifyContent="space-around">
                        <Grid item justifyContent='left' sx={{marginBottom:'20px'}} className={classes.root}>
                            {medias.length > 0 ?
                                <SwipeableTextMobileStepper images={medias} /> :
                                <img src={Payment} alt="payment" />
                            }

                        </Grid>
                        <Grid item justifyContent='right' style={{ flex: '1 100%' }}>
                            <Grid container margin='10px' justifyContent='center'>
                                <Typography variant="h4" component="div">
                                    {Number(data.amount).toFixed(3)}
                                </Typography> &nbsp;
                                <Typography variant="h6" component="div">
                                    USDT
                                </Typography>

                            </Grid>
                            <Grid container justifyContent='center'>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="h6">
                                    {data.title}
                                </Typography>

                            </Grid>
                            <Grid container justifyContent='center'>
                                <Typography variant='h6' component='p' sx={{ mb: 1.5 }} color="text.secondary">
                                    {t('recipient')} : {data.recipient}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Box sx={{ width: "50%", margin: '0 auto', height: '100px' }}>
                        <Button
                            sx={{ height: '50px', borderRadius: '30px' ,fontSize:'16px' }}
                            color='secondary'
                            variant='contained'
                            fullWidth
                            onClick={payment}
                            size="small">{t('Payment')}</Button>
                    </Box>
                </CardActions>
            </Card>}
        </Container>
    );
}