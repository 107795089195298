import { Grid } from "@mui/material";
import { useState } from "react";
import { httpClient } from "../lib/HttpClient"
import useEffectAsync from "../lib/helper/useEffectAsync"
import PaymentItem from "../components/Payment/PaymentItem";
import LayoutWithSidebar from "../components/LayoutWithSidebar";

export default function Payments({ }) {
    const [items, setItems] = useState([])

    useEffectAsync(async () => {
        var payments = await httpClient.Get('/api/payment');
        setItems(payments);
    }, [])

    return (
        items.length > 0 &&
        <Grid container >
            {items.map(it => {
                return (
                    <PaymentItem key={it.id} {...it} />
                )
            })}
        </Grid>
    )

}
