import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import dimensions from './theme/dimentions';
import palette from './theme/palette';
import { useLocalization } from './localization';
import { useEffect } from 'react';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});
const theme = createTheme({
  typography: {
    fontFamily: "IBM Plex Sans Thai Looped"
  },
  dimensions,
  palette,
  
  // direction: 'ltr',
});

export default function RTL(props) {
  const { direction } = useLocalization();

  useEffect(() => {
    theme.direction = direction;
    if (direction === 'rtl') {
      console.log("asdas");
      theme.typography.fontFamily = 'Homa'
      console.log('theme.typography.fontFamily: ', theme.typography.fontFamily);
    }
    document.body.dir = direction;
  }, [direction])

  return (

    direction == 'rtl' ? < CacheProvider value={cacheRtl} >
      < ThemeProvider theme={theme} >
        {props.children}
      </ThemeProvider >
    </CacheProvider > :

      < ThemeProvider theme={theme} >
        {props.children}
      </ThemeProvider >

  );
}