import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function Budget(props) {
    return (
        <Card elevation={5} sx={{ height: '100%', borderRadius: '20px', boxShadow: '2px -1px 10px 4px #e0dede' }}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                    style={{ flexWrap: 'nowrap' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {props.title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {props.count}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <BarChartIcon sx={{
                            color: '#1aa9b5',
                            height: 44,
                            width: 44
                        }} />
                        {/* <Avatar
                                sx={{
                                    backgroundColor: '#1aa9b5',
                                    height: 32,
                                    width: 32
                                }}
                            >
                                <BarChartIcon />
                            </Avatar> */}
                    </Grid>
                </Grid>
                {/* <Box
                        sx={{
                            pt: 2,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <ArrowDownwardIcon color="error" />
                        <Typography
                            color="error"
                            sx={{
                                mr: 1
                            }}
                            variant="body2"
                        >
                            12%
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            Since last month
                        </Typography>
                    </Box> */}
            </CardContent>
        </Card>
    );
}