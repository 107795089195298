import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import t, { useLocalization } from '../../lib/localization';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ApprovalIcon from '@mui/icons-material/Approval';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@mui/material';


export default function PaymentProgress({ session  }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [error, setError] = React.useState("");
    var steps = [
        { number: 1, label: t("payment.pending"), icon: HourglassBottomIcon },
        { number: 2, label: t("payment.confirming"), icon: ApprovalIcon },
        { number: 3, label: t("payment.transfering"), icon: ScheduleSendIcon },
        { number: 4, label: t("payment.done"), icon: DoneIcon }
    ];
    const setStep = (step) => {
        if (!!session.doneSessionOnReceiveMoney && step > 1) {
            setActiveStep(1);
        } else {
            setActiveStep(step);
        }
    }
    React.useEffect(() => {
        if (session.statusExternal === 10) {
            setError(t("payment.expired"))
            setStep(0);
            return;
        }
        if (session.statusExternal == 11) {
            setError(t("payment.failed"))
            setStep(0);
            return;
        }
        if (session.statusExternal == 1) setStep(0);
        if (session.statusExternal >= 3 && session.statusExternal <= 5) setStep(1);
        if (session.statusExternal > 5 && session.statusExternal < 9) setStep(2);
        if (session.statusExternal >= 9) setStep(4);

    }, [session.statusExternal])

    const dontShowExeededStepsIfFastpayment = c => (session.doneSessionOnReceiveMoney && (c.number === 1 || c.number === 4)) || !session.doneSessionOnReceiveMoney;

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.filter(dontShowExeededStepsIfFastpayment).map(({ icon, label }, index) => {
                    const labelProps = {};
                    if (error && index === activeStep) {
                        labelProps.optional = (
                            <Typography textAlign='center' variant="caption" color="error">
                                {error}
                            </Typography>
                        );

                        labelProps.error = true;
                        labelProps.sx = { ...labelProps.sx, textAlign: 'center' }
                    }
                    return (
                        <Step sx={{'& svg' :{fontSize:25} , '& .MuiStepLabel-label' : {
                            fontSize: 13,fontFamily:'Homa'
                        }}} key={label}>
                            <StepLabel  {...labelProps}  >{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    );
}