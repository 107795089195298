import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpClient } from "../../lib/HttpClient";
import PaymentInfo from "./PaymentInfo";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import { useLocalization } from "../../lib/localization";
var refreshTask = null;

export default function PaymentPage() {
    const { sessionId } = useParams();
    const [item, setItem] = useState();

    const updateStatus = async () => {

        if (sessionId) {
            const paymentSession = await httpClient.Get(`/api/payment/session/${sessionId}`)
            setItem(paymentSession);
        }
    }

  
    useEffectAsync(async () => {
        await updateStatus()
        if (!refreshTask) {
            refreshTask = setInterval(updateStatus, 5000);
        }
    }, [], () => clearInterval(refreshTask))

    useEffect(() => {
    }, [item])
    return (
        <PaymentInfo item={item} />
    )
}

