import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function CircularProgressWithLabel(props) {
    const value = (props.initial - props.value) * 100 / props.initial;
    const [remainedTime, setRemainedTime] = useState("");
    useEffect(() => {
        var minutes = Math.floor(props.value / 60);
        var seconds = props.value % 60;
        setRemainedTime(`${minutes}:${seconds}`)
    }, [props.value])

    return (
        <Box position="relative" color='white' display="inline-flex">
            <CircularProgress color="secondary" thickness={3} size={80} variant="determinate" value={value} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection='column'
            >
                <Typography variant="caption" fontSize='20px' color='secondary' component="div" >{remainedTime}</Typography>
            </Box>
        </Box>
    );
}


export default function Timer({ callback, initial, passed, updater }) {
    const [progress, setProgress] = React.useState(passed);

    var timepassed = 0

    React.useEffect(() => {
        var timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress - 1));
            timepassed++;
            updater && updater(timepassed);
            if (timepassed == initial)
                clearInterval(timer);
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [initial]);

    return <div style={{
        'display': 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
    }}>
        {
            progress > 0 && <CircularProgressWithLabel value={progress} initial={initial} />
        }
    </div >
}
