import Budget from "../components/Budget";
import Payments from "./Payments";
import LayoutWithSidebar from "../components/LayoutWithSidebar";
import t from "../lib/localization";
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import DashboardRecipientList from "./Payment/DashboardRecipientList";
import useEffectAsync from "../lib/helper/useEffectAsync";
import { httpClient } from "../lib/HttpClient";
import { toast } from "react-toastify";
import { useState } from "react";

export default function Home() {
    const [balance, setBalance] = useState(0);
    const [accounts, setAccounts] = useState(1);
    const [successFulPayments, setSuccessFulPayments] = useState(0);
    const [pendingPayments, setPendingPayments] = useState(0);

    useEffectAsync(async () => {
        try {
            const response = await httpClient.Get("api/account");

            const accountNumber = response.length;
            setAccounts(accountNumber);
            if (response.length > 0) {
                const totalBalance = response.map(c => c.balance).reduce((ac, curr) => ac + curr);
                setBalance(totalBalance);
            }
        } catch (error) {
            toast.error(error.toString())
        }
    }, [])

    useEffectAsync(async () => {
        try {
            const response = await httpClient.Get("api/payment/sessions");
            const pendings = response.filter(c => c.statusExternal < 9);
            const successful = response.filter(c => c.statusExternal === 9);
            setSuccessFulPayments(successful.length);
            setPendingPayments(pendings.length);

        } catch (error) {
            //ignore
        }
    }, [])
    return (
        <div>
            <LayoutWithSidebar>
                {accounts === 0 &&
                    <Grid container sx={{
                        lineHeight: 3, borderRadius: '25px',
                        boxShadow: '2px -1px 10px 4px #e0dede',
                        backgroundColor: 'rgb(200, 250, 205)'
                    }}>
                        <Grid sx={{ padding: '24px' }} item textAlign='left' xs={12} md={8}>
                            <Typography color='black' fontWeight='900' textAlign='left' variant="h5">
                                {t("home.welcome")}
                            </Typography>
                            <Typography variant='body'>
                                {t("home.intro")}
                            </Typography>
                            <Typography>
                                {t("home.start")}
                            </Typography>
                            <ul>
                                <li>{t("home.create")} <Button component={Link} to="/account" variant="contained" color="info">{t("home.wallet")}</Button> {t("home.income")}</li>
                                <li>{t("home.setup")} <Button component={Link} to="/payment/new" color="error" variant="contained">{t("home.paymentLink")}</Button> {t("home.access")}</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ width: '360px', padding: '24px', textAlign: 'center' }}>
                            <svg height="250" width="300"><use xlinkHref="/welcome.svg#img"></use></svg>
                        </Grid>
                    </Grid>
                }

                <Grid container sx={{ marginTop: '50px' }} justifyContent='space-around'  >
                    <Grid item marginBottom='20px' xs={12} md={3}>
                        <Budget title={t("home.balance")} count={balance} />
                    </Grid>
                    <Grid item marginBottom='20px' xs={12} md={3}>
                        <Budget title={t("home.successfulPayments")} count={successFulPayments} />
                    </Grid>
                    <Grid item marginBottom='20px' xs={12} md={3}>
                        <Budget title={t("home.pendingPayments")} count={pendingPayments} />
                    </Grid>
                </Grid>
                <Payments />
            </LayoutWithSidebar>
            <DashboardRecipientList />
        </div>
    );
}

