import { useDispatch, useSelector } from "react-redux";
import useEffectAsync from "../lib/helper/useEffectAsync";
import { httpClient, unauthorized$ } from "../lib/HttpClient";
import { useHistory } from 'react-router-dom';
import { sessionActions } from "../store";
import { useEffect } from "react";
import routes from "../routes/Routes";

export default function SessionManager() {

    const authenticated = useSelector((state) => !!state.session.user);
    // const unAuthenticated = useObservable(unauthorized$);
    // 

    const dispatch = useDispatch();
    const history = useHistory();
    const publicRoutes = ["payment/pay/","gw/pay","payment/create","plugins"];
    // const publicRoutes = routes.filter(r=>!r.private).map(r=>r.href);
    useEffect(() => {
        const sub = unauthorized$.subscribe(unAuthenticated => {
            if (unAuthenticated && !publicRoutes.some(r => history.location?.pathname.indexOf(r) > 0)
                && history.location?.pathname != "/") {
                history.push('/login');
            }
        });
        return () => sub.unsubscribe();
    }, [unauthorized$]);

    useEffectAsync(async () => {
        if (authenticated) {
            const response = await httpClient.Get('/api/user/settings');
            dispatch(sessionActions.updateServer(response));
        }
    }, [authenticated]);

    useEffectAsync(async () => {
        if (authenticated) {
        } else {
            try {
                const response = await httpClient.Get('/api/user');
                dispatch(sessionActions.updateUser(response));
            } catch (error) {
                history.push('/login');
            }
        }
    }, [authenticated]);
    return null;
}