import React, { useEffect, useState } from 'react';
import { Drawer, Grid, Hidden } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import routes from '../routes/Routes';
import SideNav from './SideNav';
import Navbar from './NavBar';
import t from "../lib/localization";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerContainer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
  },
  content: {
    flex: 1,
    padding: theme.spacing(5, 3, 3, 3),
    position: 'relative'
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  backArrowIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolbar: {
    [theme.breakpoints.down('lg')]: {
      ...theme.mixins.toolbar,
    },
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    display: 'none',
    left: 0,
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#babbbc',
    padding: '1em 2em',
    color: 'white',
    fontFamily: 'Homa',
  },
  logoHolder: {
    padding: '10px',
    //backgroundColor: '#f5d6f5',
    textDecoration: 'underline',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '15px',
    borderRadius: '20px',
    color: 'purple'
  }

}));



const LayoutWithSidebar = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [reportTitle, setReportTitle] = useState();
  const sidbarRoutes = routes.filter(r => r.showOnSidebar);

  useEffect(() => {
    routes.forEach((route) => {
      switch (location.pathname) {
        case `${route.href}`:
          setReportTitle(route.name);
          break;
        default:
          break;
      }
    });
  }, [location]);

  const pageTitle = `${reportTitle}`;

  return (
    <div className={classes.root}>
      <Hidden only={['lg', 'xl']}>
        <Navbar setOpenDrawer={setOpenDrawer} title={pageTitle} />
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          classes={{ paper: classes.drawer }}
        >
          <SideNav routes={sidbarRoutes} />
        </Drawer>
      </Hidden>
      <Hidden only={['xs', 'sm', 'md']}>
        <Drawer
          variant="permanent"
          classes={{ root: classes.drawerContainer, paper: classes.drawer }}
        >
          <a href='/' className={classes.logoHolder} >
            {t("logo.text")}
            {/* <svg height="64" width="150">
              <use xlinkHref="/road.svg#road" />
            </svg> */}
          </a>


          <SideNav routes={sidbarRoutes} />
        </Drawer>
      </Hidden>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container direction="column" spacing={2}>
          <Grid item>{children}</Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LayoutWithSidebar;
