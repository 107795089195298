import '../assets/css/home.css';
import number1 from '../assets/images/number1.png';
import number2 from '../assets/images/number2.png';
import number3 from '../assets/images/number3.png';
import number4 from '../assets/images/number4.png';
import time from '../assets/images/time.png';
import wallet from '../assets/images/wallet.png';
import nodejs from '../assets/images/nodejs.svg';
import microsoft_net from '../assets/images/microsoft_net.png';
import nopcommerce from '../assets/images/nopcommerce.png';
import wordpress from '../assets/images/wordpress.png';
import woo_commerce from '../assets/images/woo_commerce.png';
import chart from '../assets/images/chart.png';
import stepEnDesktop from '../assets/images/steps-desktop-en.svg';
import stepEnMobile from '../assets/images/steps-mobile-en.svg';
import globe from '../assets/images/globe.png';
import laravel from '../assets/images/laravel.png';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import t from '../lib/localization';
import Header from "../Pages/Header";

const Landing = () => {

    const history = useHistory();
    const authenticated = useSelector((state) => !!state.session.user);

    const register = () => {
        history.push('/register');
    }

    const home = () => {
        history.push('/home');
    }

    const plugin = () => {
        history.push('/plugins');
    }

    let homeSectionButton;
    if (authenticated) {
        homeSectionButton = <button className="btn btn-primary btn-lg px-4 btn-shadow mb-3 mb-md-0 ml-4" type="button" onClick={home}>{t('landing.dashboard')}</button>
    } else {
        homeSectionButton = <button className="btn btn-primary btn-lg px-4 btn-shadow mb-3 mb-md-0 ml-4" type="button" onClick={register}>{t('loginRegister')}</button>
    }

    let steps = <div className="row align-items-center justify-content-center steps">
        <img src={stepEnMobile} alt="steps" style={{width:'450px'}}/>
    </div>

    return (
        <div>
            <Header/>
            <section className="home">
                <section className="hero">
                    <div className="container">
                        <div className="row align-items-center justify-content-center no-gutters">
                            <div className="col-lg-4 col-12 mb-5 mb-lg-0 text-center text-md-right">
                                <div className="text-primary title-font text-center text-md-right" style={{ marginBottom: "32px" }}>
                                    {t('landing.caraGateway')}
                                </div>
                                <div className="text-justify hero-desc">
                                    {t('landing.caraGatewayDescription')}
                                </div>
                                <div className="col-auto">
                                    {homeSectionButton}
                                </div>
                            </div>
                            <div className="col-lg-auto col-12">
                                <div className="home-image"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="metro-items">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                                <span className="title"> {t('landing.whyCaraWay')} </span>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center items">
                            <div className="text-center mb-4 mb-md-0 col-md-3 col-12">
                                <img width="90" height="90" alt={t('landing.reasonOne')} src={globe} className="mb-4" />
                                <h6 className="text-center mb-3 item-font">{t('landing.reasonOne')}</h6>
                                <div className="description-font">
                                    {t('landing.reasonOneDescription')}
                                </div>
                            </div>
                            <div className="text-center mb-4 mb-md-0 col-md-3 col-12">
                                <img width="90" height="90" alt={t('landing.reasonTwo')} src={time} className="mb-4" />
                                <h6 className="text-center mb-3 item-font">{t('landing.reasonTwo')}</h6>
                                <div className="description-font">
                                    {t('landing.reasonTwoDescription')}
                                </div>
                            </div>
                            <div className="text-center mb-4 mb-md-0 col-md-3 col-12">
                                <img width="90" height="90" alt={t('landing.reasonThree')} src={chart} className="mb-4" />
                                <h6 className="text-center mb-3 item-font">{t('landing.reasonThree')}</h6>
                                <div className="description-font">
                                    {t('landing.reasonThreeDescription')}
                                </div>
                            </div>
                            <div className="text-center mb-4 mb-md-0 col-md-3 col-12">
                                <img width="90" height="90" alt={t('landing.reasonFour')} src={wallet} className="mb-4" />
                                <h6 className="text-center mb-3 item-font">{t('landing.reasonFour')}</h6>
                                <div className="description-font">
                                    {t('landing.reasonFourDescription')}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="add-gateway-flow">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                                <span className="title">{t('landing.gatewayFlow')}</span>
                            </div>
                        </div>
                        {steps}
                    </div>
                </section>
                <section className="plugins">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 col-12 mb-5 mb-lg-0 text-center text-md-right">
                                <h2 className="title mb-4 text-center text-md-right">
                                    {t('landing.caraWayPlugins')}
                                </h2>
                                <div className="description-font mb-4 text-center text-md-right">
                                    {t('landing.caraWayPluginsDescription')}
                                </div>
                                <button className="btn btn-primary text-white px-4 py-3 mb-5 " type="button" onClick={plugin}>{t('landing.pluginButton')}</button>
                            </div>
                            <div className="col">
                                <div className="row align-items-center justify-content-center">
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={wordpress} alt="wordpress" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={woo_commerce} alt="woo_commerce" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={laravel} alt="laravel" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={nopcommerce} alt="nopcommerce" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={microsoft_net} alt="microsoft_net" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-sm-3 col-xl-2 col-12">
                                        <div className="card border-0 plugin-card">
                                            <div className="card-body">
                                                <div className="row align-items-center justify-content-center"
                                                    style={{ height: "100%" }}>
                                                    <img src={nodejs} alt="nodejs" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default Landing;