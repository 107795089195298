import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

export default function PasswordTextInput({
    name, onChange, label, formik, type, info
}) {
    const [showPass, setShowPass] = useState(false);
    const handleClickShowPassword = () => {
        setShowPass(!showPass);
    }
    return (
            <TextField autoComplete="off"
                margin='normal'
                error={formik.errors[name]}
                type={!showPass ? 'password' : 'text'}
                helperText={formik.errors[name]}
                fullWidth value={formik.values[name] || ""}
                label={label ?? name.toUpperCase()}
                name={name}
                onChange={formik.handleChange || ""} 
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />
    )
}
