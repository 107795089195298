import * as React from 'react';
import { Card, Grid, CardActions, CardContent, Button, Typography, Chip, Tooltip } from "@mui/material";
import { Link } from 'react-router-dom';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import t from '../../lib/localization';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
export default function PaymentItem({ recipient, title, id, amount, instantPayout }) {

    return (
        <Card elevation={5} sx={{ margin: '10px', borderRadius: '10px', position: 'relative', height: 200, minWidth: '250px' }}>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h5" component="h3" gutterBottom>
                            {title} {instantPayout &&
                                <Tooltip
                                    title={
                                        t("payment.instantPayout")
                                    }
                                    placement="top"
                                >
                                    <FlashOnIcon color='warning' />
                                </Tooltip>
                            }
                        </Typography>

                    </Grid>
                    <Grid item style={{ position: 'absolute', top: '50%' }}>
                        <Typography color="text.secondary" component="p">
                            {recipient}
                        </Typography>
                    </Grid>
                    <Grid item style={{ position: 'absolute', top: '20%' }}>
                        <Chip icon={<MonetizationOnIcon />} variant='outlined' label={`${amount} ${t("sharedTether")}`} />
                    </Grid>

                </Grid>
            </CardContent>
            <CardActions style={{ position: 'absolute', width: '100%', bottom: 0 }}>
                <Button
                    color='success'
                    variant='outlined'
                    component={Link}
                    fullWidth
                    to={`/payment/create/${id}`}
                    // onClick={createPayment(id)}
                    size="small">{t('payment.pay')}</Button>
            </CardActions>
        </Card>
    );
}