import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import WithHelpLayout from "../../components/Common/WithHelpLayout";
import LayoutWithSidebar from "../../components/LayoutWithSidebar";
import ApiKeysTable from "../../components/Settings/ApiKeys";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import { httpClient } from "../../lib/HttpClient";
import t from "../../lib/localization";
import PaymentSettings from './PaymentSettings';

export default function Settings() {

    const [apiKeys, setApiKeys] = useState([]);

    useEffectAsync(async () => {
        try {
            var response = await httpClient.Get("api/user/apikey");
            setApiKeys(response);
        } catch (error) {
            toast.error(error.toString())
        }
    }, [])

    const handleClickNewApiKey = async () => {
        try {
            const response = await httpClient.Post("api/user/apikey");
            setApiKeys(prev => [...prev, response]);
        } catch (error) {

        }

    }

    return (<>
        <LayoutWithSidebar>
            <WithHelpLayout help={t("Here you can generate an API KEY to your for your store.This api-key is required to be set at x-api-key header.")}>
                <Typography variant="h4">
                    {t("settings.Apikeys")}
                </Typography>
            </WithHelpLayout>

            <Grid container>
                {
                    apiKeys.length == 0 ?
                        <Button onClick={handleClickNewApiKey} variant="outlined" startIcon={<AddBoxIcon />} >
                            {t("settings.generateApiKey")}
                        </Button> :
                        <ApiKeysTable />
                }
            </Grid>
            <Divider />
            <PaymentSettings />
        </LayoutWithSidebar>
    </>
    )
}
