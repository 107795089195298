import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

var authentication = {};
export const httpClient = {
    Post,
    Get,
    Put,
}

export const unauthorized$ = new BehaviorSubject(false);
async function Post(url, model,multiPartFormData) {
    if(!!multiPartFormData){
        return MultiPartFormData(url,model,'POST')
    }
    const request = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...authentication
        },
        body: JSON.stringify(model)
    }
    try {
        const response = await fetch(url, request);
        return handleResponse(response);
    }
    catch (error) {
        return handleError(error);
    }
}
async function Put(url, model,multiPartFormData) {
    if(!!multiPartFormData){
        return MultiPartFormData(url,model,'PUT')
    }
    const request = {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            ...authentication
        },
        body: JSON.stringify(model)
    }
    try {
        const response = await fetch(url, request);
        return handleResponse(response);
    }
    catch (error) {
        return handleError(error);
    }
}
function Get(url) {

    const request = {
        method: "GET",
        headers: {
            ...authentication,
            'Content-Type': 'application/json'
        },
    }

    return fetch(url, request).then(handleResponse, handleError);
}
function handleResponse(response) {
    if (response.status == 401 || (response.status == 200 && response.redirected)) {
        unauthorized$.next(true);
       return;
    }

    return new Promise((resolve, reject) => {
       
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                try {
                    response.json().then(json => resolve(json));
                } catch (error) {
                }
            } else {
                resolve();
            }
        }
        else {
            // return error message from response body
            response.text().then(text => {
                try {
                    var json = JSON.parse(text);
                    if (json.message) {
                        reject(json.message);
                    }
                    var errorMessage = "";
                    for (var prop in json)
                        errorMessage += json[prop];
                    reject(errorMessage);
                } catch (e) {
                    reject(text);
                }
            });
        }
    });
}
function handleError(error) {

    return Promise.reject(error && error.message);
}


async function MultiPartFormData(url, data,method) {
    const formData = new FormData();

    for (const name in data) {
        formData.append(name, data[name]);
    }
    try {
        const response = await fetch(url, {
            method: method,
            body: formData,
            headers: {
                ...authentication
            },
        });
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}
