import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { toast } from "react-toastify";
import { httpClient } from "../../lib/HttpClient";
import t from "../../lib/localization";
import LayoutWithSidebar from "../../components/LayoutWithSidebar";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import AddLinkIcon from '@mui/icons-material/AddLink';
import SeverityPill from '../../components/SeverityPill';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    root: { //TODO: Replace this with a dynamic value
        // [theme.breakpoints.down('sm')]: {
        //     width: '420px !important',
        // },
        // [theme.breakpoints.between('sm', 'md')]: {
        //     width: '700px !important',
        // },
        // [theme.breakpoints.between('md', 'lg')]: {
        //     width: '950px !important',
        // },
        // [theme.breakpoints.between('lg', 'xl')]: {
        //     width: '1030px !important',
        // },
        // [theme.breakpoints.up('xl')]: {
        //     width: '1250px !important',
        // },
    },
    table: { //TODO: Replace this with a dynamic value
        // [theme.breakpoints.down('sm')]: {
        //     width: '750px !important',
        // },
        // [theme.breakpoints.between('sm', 'md')]: {
        //     width: '900px !important',
        // },
        // [theme.breakpoints.between('md', 'lg')]: {
        //     width: '1000px !important',
        // },
        // [theme.breakpoints.between('lg', 'xl')]: {
        //     width: '1200px !important',
        // },
        // [theme.breakpoints.up('xl')]: {
        //     width: '1500px !important',
        // },
    },
}));

export default function DashboardRecipientList() {
    const classes = useStyles();
    const [payments, setPayments] = useState([]);

    useEffectAsync(async () => {
        try {
            var payments = await httpClient.Get("/api/payment/sessions/0/10");
            setPayments(payments);
        } catch (error) {

        }
    }, [])

    const copyLink = (item) => {
        navigator.clipboard.writeText(`https://tronscan.org/#/transaction/${item.txId}`);
        toast.success(t("payment.txIdCopied"));
    }

    return (
        <LayoutWithSidebar>
            <TableContainer className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('payment.paymentTitle')}</TableCell>
                            <TableCell>{t('payment.transactionDetails')}</TableCell>
                            <TableCell>{t('payment.payoutAddress')}</TableCell>
                            <TableCell>{t('payment.amount')}</TableCell>
                            <TableCell>{t('payment.paymentId')}</TableCell>
                            {/* <TableCell>{t('payment.token')}</TableCell> */}
                            <TableCell>{t('payment.status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((item) => (
                            <TableRow key={item.id} >
                                {/* <TableCell className={classes.columnAction} padding="none">
                                <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell> */}
                                <TableCell> {item.title}</TableCell>
                                <TableCell>
                                    {
                                        item.txId &&
                                        <IconButton onClick={() => copyLink(item)} aria-label="delete" color="primary">
                                            <AddLinkIcon />
                                        </IconButton>
                                    }
                                </TableCell>
                                <TableCell>{item.payoutAddress}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.sessionId}</TableCell>
                                {/* <TableCell>{item.tokenToPay}</TableCell> */}
                                <TableCell>
                                    <SeverityPill
                                        color={(item.status === 'WaitingToReceive' && 'secondary')
                                            || (item.status === 'Done' && 'success')
                                            || (item.status === 'Expired' && 'error')
                                            || (item.status === 'Failed' && 'error')
                                            || (item.status === 'Declined' && 'error')
                                            || 'warning'}
                                    >
                                        {(item.status === 'WaitingToReceive' || item.status === 'Done' || item.status === 'Expired' || item.status === 'Failed' || item.status === 'Declined') ? t(item.status) : t("InProgress")}
                                    </SeverityPill>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
        </LayoutWithSidebar >
    )

}