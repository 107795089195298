import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import ImageUploader from "../components/Common/ImageUploader";
import WithHelpLayout from "../components/Common/WithHelpLayout";
import EditViewLayout from "../components/EditViewLayout";
import LayoutWithSidebar from "../components/LayoutWithSidebar";
import RemoveDialog from "../components/RemoveDialog";
import SelectField from "../components/SelectInput";
import TextInput from "../components/TextInput";
import t, { languageList } from "../lib/localization";

const useStyles = makeStyles((theme) => ({

    pickerWrapper: {
        background: '#EFEFE3',
        width: 100,
        height: 100,
        marginLeft: -8,
        "& svg": {
            fontSize: 28
        }
    }
}));

export default function PaymentNew() {
    const settings = useSelector((state) => state.session.server);
    const [photos, setPhotos] = useState([])
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const anchorEl = useRef();
    const [open, setOpen] = useState(false);
    const handleSelectPhoto = (files) => {
        setPhotos(files);
    }
    const handleRemoveResult = (result) => {
        if (result) {

            formik.setFieldValue("medias", formik.values.medias.filter(c => c.id != deleteId));
        }

        setRemoveDialogShown(false);
    }

    useEffect(() => {

        var i = 0;
        if (photos) {
            for (const photo of photos) {
                const imageUrl = window.URL.createObjectURL(photo);

                formik.setFieldValue(`file${i}`, photos[i]);

                i++;
            }
            // formik.setFieldValue('files', [...photos]);
        }
    }, [photos])

    var schema = Yup.object({
        title: Yup.string().required(t("shared.mandatory")).max(30, t("shared.titleLength")),
        amount: Yup.number().min(3).required(t("shared.mandatory")),
        accountId: Yup.string().required(t("shared.mandatory")),
        instantPayout: Yup.bool(),
        doneSessionOnReceiveMoney: Yup.bool()
    })

    var formik = useFormik({


        validationSchema: schema,
        initialErrors: false,
        initialValues: { medias: [] }
    })

    const getWhatWillReceive = () => {
        if (!!formik.values.amount) {
            const value = (((100 - settings?.commissionInPercent) * formik.values.amount) / 100) - (formik.values.instantPayout ? 1 : 0);
            return value.toFixed(5);
        }
        return 0;
    }

    const deletePicture = async (id) => {

        setRemoveDialogShown(true);
        setDeleteId(id);
    }

    const handleChange = (event) => {
        formik.setFieldValue("instantPayout", event.target.checked);

    };
    const handleChangeDoneSessionOnReceiveMoney = (event) => {
        formik.setFieldValue("doneSessionOnReceiveMoney", event.target.checked);

    };
    return (
        <LayoutWithSidebar>
            <EditViewLayout isFormData endpoint="payment" formik={formik} >
                <Card elevation={2}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextInput formik={formik} label={t("paymentTitle")} name="title" />
                            </Grid>
                            <Grid item>
                                <TextInput type="number" formik={formik} label={t("paymentAmount")} name="amount" />
                            </Grid>
                            <Grid item>
                                <WithHelpLayout help={t("paymentNew.AmoutWillRecive")}>
                                    <Typography textAlign='center' color='primary' variant='body2' alignSelf='center'>
                                        {t("payment.youWillReceive")} : &nbsp;
                                    </Typography>
                                    <Typography textAlign='center' fontSize='20px' fontWeight='700'>
                                        {getWhatWillReceive()}
                                    </Typography>
                                </WithHelpLayout>
                            </Grid>
                            <Grid item>
                                <SelectField
                                    info={t("paymentNew.WalletInfo")}
                                    margin="normal"
                                    formik={formik}
                                    emptyValue={null}
                                    endpoint={`/api/recipient/accounts`}
                                    label={t("paymentAccount")}
                                    name="accountId"
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item>
                                <WithHelpLayout help={t("paymentNew.hintOne")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox value={formik.values.instantPayout} checked={!!formik.values.instantPayout} onChange={handleChange} name="instantPayout" />
                                        }
                                        label={t("payment.instantPayout")}
                                    />
                                </WithHelpLayout>
                            </Grid>
                            <Grid item>
                                
                            </Grid>
                            {formik.values?.medias.length > 0 ?
                                <Grid item sx={{ width: '100%' }}>
                                    <ImageList cols={3} rowHeight='120' >
                                        {formik.values?.medias.map((it) => (
                                            <ImageListItem key={it.img}>
                                                <img
                                                    src={`${it.path}`}
                                                    srcSet={`${it.path}`}
                                                    alt={it.name}
                                                    loading="lazy"
                                                />
                                                <ImageListItemBar
                                                    title={it.name}
                                                    // subtitle={item.author}
                                                    actionIcon={
                                                        <IconButton
                                                            onClick={() => deletePicture(it.id)}
                                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                            aria-label={`info about ${it.title}`}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>

                                        ))}
                                    </ImageList>
                                </Grid> :
                                ""
                            }

                            <Grid item>
                                <Typography className='s20'>
                                    {t("payment.addMedia")}
                                </Typography>
                                <ImageUploader multiple
                                    receiveFiles={handleSelectPhoto} />
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('paymentNew.moreOptions')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SelectField
                            info={t("paymentNew.defaultLanguage.hint")}
                            margin="normal"
                            formik={formik}
                            data={languageList}
                            emptyValue={null}
                            label={t("paymentNew.defaultLanguage")}
                            name="paymentPageDefaultLanguage"
                            variant="filled"
                            keyGetter={i => i.code}
                            titleGetter={i => i.name}
                        />
                        <WithHelpLayout help={t("paymentNew.DoneSessionOnReceiveMoney.Hint")}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox value={formik.values.doneSessionOnReceiveMoney} checked={!!formik.values.doneSessionOnReceiveMoney} onChange={handleChangeDoneSessionOnReceiveMoney} name="doneSessionOnReceiveMoney" />
                                        }
                                        label={t("paymentNew.DoneSessionOnReceiveMoney")}
                                    />
                                </WithHelpLayout>
                    </AccordionDetails>
                </Accordion>
                <RemoveDialog open={removeDialogShown} endpoint={"payment/picture"} itemId={deleteId} onResult={handleRemoveResult} />
                {/* <ClickAwayListener onClickAway={() => setOpen(false)}> */}
                
            </EditViewLayout>
        </LayoutWithSidebar >
    )
}


