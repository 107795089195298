import React from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Grid, useMediaQuery, Button, Card, Link, useTheme, Typography, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { toast } from 'react-toastify';
import { httpClient } from '../../lib/HttpClient';
import { makeStyles } from "@mui/styles"
import StartPage from '../StartPage';
import t from '../../lib/localization';
import TextInput from '../../components/TextInput';
import PasswordTextInput from '../../components/PasswordTextInput';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  resetPassword: {
    cursor: 'pointer',
  },
  login: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
}));

const LoginForm = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const registrationEnabled = useSelector((state) => (state.session.server ? state.session.server.registration : false));
  const emailEnabled = useSelector((state) => (state.session.server ? state.session.server.emailEnabled : false));

  var schema = Yup.object({
    email: Yup.string().required(t("loginRequired")),
    password: Yup.string().required(t("loginRequired")),
  })

  var formik = useFormik({

    validationSchema: schema,
    initialErrors: false,
    initialValues: {}
  })

  const handleLogin = async () => {
    try {
      const response = await httpClient.Post("/api/user/login", formik.values);
      const user = await httpClient.Get("/api/user");
      dispatch(sessionActions.updateUser(user))
      history.push("/home");
    } catch (error) {
      toast.error(t("shared.invalidCredentials"))
    }
  };



  return (
    <StartPage>
      <Card elevation={12}>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid container item>
              <Grid item xs>
                <Typography className={classes.login} color="primary">
                  {t('loginLogin')}
                </Typography>
              </Grid>
            </Grid>
            {useMediaQuery(theme.breakpoints.down('md'))
              && (
                <Grid item className={classes.logoContainer}>
                  <svg height="64" width="240">
                    <use xlinkHref="/logo.svg#img" />
                  </svg>
                </Grid>
              )}
            <Grid item>
              <TextInput formik={formik}
                label={t('userEmail')}
                name="email" />
            </Grid>
            <Grid item>
              <PasswordTextInput
                label={t('userPassword')}
                formik={formik} name="password" />
            </Grid>
            <Grid item>
              <Button
                onClick={handleLogin}
                variant="contained"
                color="success"
                disabled={!formik.isValid}
                fullWidth
              >
                {t('loginLogin')}
              </Button>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Typography color="primary">{t('loginAccount')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => history.push('/register')} color="primary" variant="outlined" fullWidth>
                  {t('loginRegister')}
                </Button>
              </Grid>
            </Grid>
            {emailEnabled && (
              <Grid item container justify="flex-end">
                <Grid item>
                  <Link onClick={() => history.push('/reset-password')} className={classes.resetPassword} underline="none">{t('loginReset')}</Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </StartPage>
  );
};

export default LoginForm;
