import { motion } from 'framer-motion';

const pathVariants = {
    hidden: {
        opacity: 0,
        rotate: -90,
    },
    visible: {
        opacity: 1,
        rotate: 0,
        transition: {
            duration: 2,
            ease: "easeInOut"
        }
    }
}
const svgVariant = {
    hidden: {
        opacity: 0,
        scale: 4,
        x: 550,
        y: 100
    },
    visible: {
        opacity: 1,
        x: 0,
        y: 0,
        scale: 1,
        transition: {
            duration: 1,
            ease: "easeInOut"
        }
    }
}

const writingVariant = {
    hidden: {
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        pathLength: 1,
        fill: "rgba(255, 255, 255, 1)",
        transition: {
            delay: 1,
            duration: 2,
        }
    }
}

export default function SuccessfulPayment({ handleFinished, style }) {
    return (
        <motion.svg
            variants={svgVariant}
            initial="hidden"
            animate="visible"
            xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" id="svg" 
            version="1.1" width="400" height="250" viewBox="15,0, 100,150">
            <motion.path
                // onAnimationComplete={() => handleFinished()}
                variants={writingVariant}
                initial="hidden"
                animate="visible"

                fill="#fff" d="M106.4,21.6C106.4,21.6,106.4,21.6,106.4,21.6C83-1.9,45-1.9,21.6,21.6C-1.9,45-1.9,83,21.6,106.4 C33.3,118.1,48.6,124,64,124c7.4,0,14.9-1.4,21.9-4.1c7.5-2.9,14.5-7.4,20.5-13.4c9.6-9.6,15.3-21.7,17-34.2 C125.9,54.3,120.3,35.4,106.4,21.6z" />
            <circle cx="64" cy="64" r="36" fill="#8ce5c3" />
            <motion.path
                variants={pathVariants}
                initial="hidden"
                animate="visible"
                fill="#444b54" d="M64,79c-0.8,0-1.6-0.3-2.1-0.9l-15-15c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0l12.7,12.7L91.7,39 c1.1-1.3,3-1.4,4.2-0.3c1.3,1.1,1.4,3,0.3,4.2l-30,35c-0.5,0.6-1.3,1-2.2,1C64.1,79,64,79,64,79z" />
            <path fill="#444b54" d="M64,127C29.3,127,1,98.7,1,64S29.3,1,64,1s63,28.3,63,63S98.7,127,64,127z M64,7C32.6,7,7,32.6,7,64 s25.6,57,57,57s57-25.6,57-57S95.4,7,64,7z" />

        </motion.svg>
    )
}