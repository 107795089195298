import './App.css';
import RTL from './lib/RTL';
import { useRoutes } from './routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import SessionManager from './components/SessionManager';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from './lib/localization';
import 'react-toastify/dist/ReactToastify.css';
import './lib/helper/string.extensions'


function App() {
  return (
    <LocalizationProvider>
      <RTL>
        <Router>
          <SessionManager />
          {
            useRoutes()
          }
          <ToastContainer position={'bottom-right'} bodyStyle={{ width: '100%', borderRadius: 0 }}  hideProgressBar />
        </Router>
      </RTL>
    </LocalizationProvider>
  );
}

export default App;
