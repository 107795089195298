import { Tooltip } from "@mui/material";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import t from "../lib/localization";

export default function CopyToMemory({ text, children }) {
    const [copiedText, setCopiedText] = useState();
    return (
        <CopyToClipboard
            text={text}
            onCopy={() => setCopiedText("ni ni-active-40")}
        >
            <Tooltip
                title={
                    copiedText === "ni ni-active-40"
                        ? t("shared.thisWasCopied")
                        : t("shared.copyToClipboard")
                }
                placement="top"
            >
                {children}
            </Tooltip>
        </CopyToClipboard>
    )
}