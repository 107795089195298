import { Avatar, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { httpClient } from '../lib/HttpClient';
import t from '../lib/localization';
import LogoutIcon from '@mui/icons-material/Logout';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  listItem: {
    maxWidth: '90%',
    left: '5%',
    borderRadius: '10px !important',
    margin: '5px !important',
    "& span" : {
      fontSize:'16px',
      fontWeight : 700
    }
  },
  profile: {
    margin: '23px !important',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: 100,
    marginLeft: '11% !important',
    width: '80% !important',
  }
}))
const SideNav = ({ routes }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.session.user);



  return (
    <List disablePadding style={{ paddingTop: '16px' }}>
      <Link to='/profile'>
        <Grid container alignItems='center' sx={{ padding: '22px' }} className={`${classes.listItem} ${classes.profile}`}>
          <Grid item textAlign='center' >
            <Avatar
              sizes=''
              alt="Remy Sharp"
              src={user.recipient.medias[0]?.path}
              sx={{ width: 56, height: 56 }}
            />
          </Grid>
          <Grid item textAlign='center' sx={{ marginLeft: '20px' }}>
            <Grid container><Typography fontWeight='700' variant='h6'>{user.recipient.name}</Typography></Grid>
            <Grid container><Typography variant='caption'>recipient</Typography></Grid>
          </Grid>
        </Grid>
      </Link>

      {routes.map((route) => (route.subheader ? (
        <>
          <Divider />
          <ListSubheader>{route.subheader}</ListSubheader>
        </>
      ) : (
        <ListItem
          classes={{ root: classes.listItem }}
          disableRipple
          component={Link}
          key={route.href || route.subheader}
          button
          to={route.href}
          selected={route.href != '/' && location.pathname.match(route.match || route.href)}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItem>
      )))}
      {
        !!user ? <ListItem
          classes={{ root: classes.listItem }}
          disableRipple
          component={Link}
          button
          onClick={async () => {
            await httpClient.Post("/api/user/logout");
            window.location.href = "/"
          }}
        >
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={t('shared.logout')} />
        </ListItem> : ""
      }
    </List>

  );
};

export default SideNav;
