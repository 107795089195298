import InfoIcon from '@mui/icons-material/Info';
import { Button, Fade, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import t from "../../lib/localization";

const useStyle = makeStyles(theme => {

    return ({

        root: {
            padding: '20px',
            borderRadius: '10px',
            border: '1px solid orange',
            backgroundColor: '#fff6ea'
        },
        icon: {
            color: 'orange', marginLeft: '-10px',
            marginRight: '5px', marginBottom: '10px'
        },
        button: { background: 'orange !important', fontSize: '20px', color: 'white !important', width: '200px !important', borderRadius: '10px !important' }
    })
})

export default function TrasnferFeeCaution({ item, setCaution }) {
    const classes = useStyle();
    return <>
        <Fade in={true}>
            <Grid className={classes.root} container>
                <Grid container>
                    <InfoIcon fontSize="large" sx={{
                        color: 'orange', marginLeft: '-10px',
                        marginRight: '5px', marginBottom: '10px'
                    }} />
                    <Typography sx={{fontFamily:'Homa'}} variant="h4" >{t('payment.notice')}</Typography>
                </Grid>
                <Grid container>
                    <Typography sx={{fontFamily:'Homa'}} variant="h6">
                        {t('payment.watchTheFee').format(Number(item?.amount), Number(item?.amount) + 1)}
                    </Typography>
                </Grid>
                <Grid container justifyContent='center'>
                    <Button sx={{fontSize:theme=>theme.direction==='rtl'? 16 :12 , mt:4,fontFamily:'Homa'} } onClick={() => setCaution(false)} className={classes.button} variant="contained">
                        {t("payment.okShow")}
                    </Button>
                </Grid>
            </Grid>
        </Fade>
    </>
}