import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({

    profile: {
        height: 100,
        width: '75% !important',
    }
}))
export default function RecipientInfo({ recipient }) {
    const classes = useStyles();

    return (
        <Grid container alignItems='center' sx={{ padding: '22px', flexWrap: 'nowrap' }}
            className={`${classes.listItem} ${classes.profile}`}>
            <Grid item textAlign='center' >
                <Avatar
                    variant='square'
                    sizes=''
                    alt="Remy Sharp"
                    src={recipient.medias[0]?.path}
                    sx={{ width: 80, height: 80 }}
                />
            </Grid>
            <Grid item textAlign='center' sx={{ marginLeft: '20px' }}>
                <Grid container>
                    <Typography variant='caption' fontSize='15px'>Recipient: </Typography>&nbsp;&nbsp;
                    <Typography fontWeight='700' variant='h6'> {recipient.name}</Typography></Grid>
                <Grid container><Typography noWrap sx={{width:'240px'}} variant='caption'>
                    <a href={`/${recipient.webSiteUrl}`}
                    >{recipient.webSiteUrl}</a></Typography></Grid>
            </Grid>
        </Grid>
    )
}