import { createContext, useContext, useState } from "react"
import en from '../resouces/en.json'
import fa from '../resouces/fa.json'

export default function t(key) {
    return selectedLanguage.data[key] ?? key
}

const supportedLanguages = {
    en: { data: en, name: "English", direction: 'ltr' },
    fa: { data: fa, name: "فارسی", direction: 'rtl' }
}
const defaultLanguage = "en";
const language = defaultLanguage;

let selectedLanguage = supportedLanguages[language] ?? supportedLanguages["en"];



const setSelectedLanguage = (language) => {
    selectedLanguage = supportedLanguages[language];
    localStorage.setItem('language', language);
}

var LocalizationContext = createContext();
export const languageList = Object.entries(supportedLanguages).map((values) => ({ code: values[0], name: values[1].name }));

export function LocalizationProvider(props) {
    const [language, setLanguage] = useState(defaultLanguage);
    const [direction, setDirection] = useState(supportedLanguages[defaultLanguage].direction);
    const handleLanguageChange = (nextLanguage) => {
        nextLanguage = nextLanguage == null || nextLanguage === 'null' ? 'en' : nextLanguage;
        setSelectedLanguage(nextLanguage);
        setLanguage(nextLanguage ?? 'en');
        setDirection(supportedLanguages[nextLanguage].direction)
        // window.location.reload();
    };

    return <LocalizationContext.Provider value={{ language, setLanguage: handleLanguageChange, languageList, direction }}>
        {props.children}
    </LocalizationContext.Provider>
}

export const useLocalization = () => {
    return useContext(LocalizationContext);
}