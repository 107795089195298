import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { Switch, Route } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Home from "../Pages/Home";
import RecipientNew from "../Pages/RecipientNew"
import TokenNew from '../Pages/TokenNew'
import AccountNew from "../Pages/AccountNew";
import PaymentNew from "../Pages/PaymentNew";
import PaymentPage from "../Pages/Payment/PaymentPage";
import RegisterForm from "../Pages/registration/RegisterForm";
import LoginForm from "../Pages/registration/LoginForm";
import Landing from "../Pages/Landing";
import Plugins from "../Pages/Plugins";
import PaymentSessionsList from "../Pages/Payment/PaymentSessionsListPage";
import CreatePaymentPage from "../Pages/Payment/CreatePaymentPage";
import AccountsPage from '../Pages/Account/AccountsPage'
import PaymentsPage from "../Pages/Payment/PaymentsPage";
import Settings from "../Pages/Settings/Settings";
import GatewayPaymentPage from "../Pages/Gateway/GatewayPaymentPage";
import t from "../lib/localization";
import Profile from '../Pages/User/Profile';

var routes = [
    { name: t("profile"), href: "/profile", private: true, component: <Profile />, showOnSidebar: false },
    { name: t("landing"), href: "/", private: false, component: <Landing />, showOnSidebar: false },
    { name: t("plugins"), href: "/plugins", private: false, component: <Plugins />, showOnSidebar: false },
    { name: t("menuItem.home"), href: "/home", private: true, component: <Home />, showOnSidebar: true, icon: <DashboardIcon /> },
    { name: t("menuItem.newRecipient"), href: "/recipient/new", private: true, component: <RecipientNew />, showOnSidebar: false },
    { name: t("menuItem.Accounts"), href: "/accounts", private: true, component: <AccountsPage />, showOnSidebar: true, icon: <CreditCardIcon /> },
    { name: t("menuItem.newAccount"), href: "/account/:id?", private: true, component: <AccountNew />, showOnSidebar: false },

    { name: t("menuItem.Payments"), href: "/payments", private: true, component: <PaymentsPage />, showOnSidebar: true, icon: <AddLinkIcon /> },
    { name: t("menuItem.payment"), href: "/payment/:id?", private: true, component: <PaymentNew />, showOnSidebar: false },

    // { name: t("menuItem.newPayment"), href: "/payment/new", private: true, component: <PaymentNew />, showOnSidebar: true, icon: <AddLinkIcon /> },
    { name: t("menuItem.payment"), href: "/payment/pay/:sessionId", private: false, component: <PaymentPage />, showOnSidebar: false },
    { name: t("menuItem.payment"), href: "/gw/pay/:sessionId", private: false, component: <GatewayPaymentPage />, showOnSidebar: false },
    { name: t("menuItem.create"), href: "/payment/create/:id", private: false, component: <CreatePaymentPage />, showOnSidebar: false },
    { name: t("menuItem.tokenNew"), href: "/token/new", private: true, component: <TokenNew />, showOnSidebar: false, icon: <CurrencyLiraIcon /> },
    { name: t("loginRegister"), href: "/register", private: false, component: <RegisterForm />, showOnSidebar: false },
    { name: t("loginLogin"), href: "/login", private: false, component: <LoginForm />, showOnSidebar: false },
    { name: t("menuItem.paymentSessionList"), href: "/sessions", private: true, component: <PaymentSessionsList />, showOnSidebar: true, icon: <ListAltIcon /> },
    { name: t("menuItem.settings"), href: "/settings", private: true, component: <Settings />, showOnSidebar: true , icon:<DisplaySettingsIcon/> },
]
export default routes;

export function useRoutes() {
    const loggedin = useSelector((state) => !!state.session.user);


    const [privateRoutes, setPrivateRoutes] = useState([]);

    useEffect(() => {
        if (loggedin) {
            setPrivateRoutes(routes.filter(r => r.private))
        } else {
            setPrivateRoutes([]);
        }
    }, [loggedin])
    return (
        <>
            <Switch>
                {
                    routes.filter(r => !r.private).map((r, i) => {
                        return <Route key={i} exact path={r.href}>
                            {r.component}
                        </Route>
                    })
                }
                {/* <Redirect to="/404" /> */}
            </Switch>
            {
                !loggedin && privateRoutes.length > 0 ? (<LinearProgress />) : <Switch>
                    {
                        privateRoutes.map((r, i) => {
                            return <Route key={i} exact path={r.href}>
                                {r.component}
                            </Route>
                        })
                    }

                    {/* <Route path="/404" component={NotFoundPage} /> */}
                    {/* <Redirect to="/404" /> */}
                </Switch>
            }

        </>
    )
}
