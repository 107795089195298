import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { httpClient } from "../../lib/HttpClient";
import { useState } from "react";
import { toast } from "react-toastify";
import t from "../../lib/localization";
import PaymentInfo from "../Payment/PaymentInfo";
import useEffectAsync from "../../lib/helper/useEffectAsync";

var refreshTask = null;

export default function GatewayPaymentPage() {
    const { sessionId } = useParams();
    const [startPolling, setStartPolling] = useState(false);
    const [item, setItem] = useState(null);
    const updateStatus = async () => {

        if (sessionId) {
            const paymentSession = await httpClient.Get(`/api/payment/session/${sessionId}`)
            setItem(paymentSession);
        }
    }

    useEffectAsync(async () => {
        if (startPolling) {
            await updateStatus()
            if (!refreshTask) {
                refreshTask = setInterval(updateStatus, 5000);
            }
        }
    }, [startPolling], () => clearInterval(refreshTask))

    useEffectAsync(async () => {
        try {
            var session = await httpClient.Post(`/api/gateway/start/${sessionId}`);
            setItem(session);
            setStartPolling(true);
        } catch (error) {
            toast.error(error.toString())
        }
    }, [])

    const callResult = (declined) => window.location.href = `/api/gateway/result?declined=${declined}&sessionId=${item.sessionId}`;

    useEffectAsync(async () => {
        if (item) {
            if (item.statusExternal >= 9) callResult(false);
            if (!item.instantPayout) {
                if (item.mode === 2) {
                    if (item.statusExternal >= 3 && item.statusExternal <= 9) {
                        callResult(false);
                    }
                }
            }
        }
    }, [item?.statusExternal])

    return (
        <PaymentInfo item={item}
            declineButton={<Button fullWidth onClick={() => callResult(true)} variant='contained'>{t('payment.decline')}</Button>}
        />
    )
}