import { ClickAwayListener, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import WithHelpLayout from "./Common/WithHelpLayout";

export default function TextInput({
    name, onChange, label, formik, type, info,value ,error
}) {
    const isPassword = name.toLowerCase().includes("password");

    return (
        <WithHelpLayout help={info}>
            <TextField autoComplete="off"
                margin='normal'
                error={error || formik.errors[name]} 
                type={type || (isPassword ? 'password' : 'text')}
                helperText={error || formik.errors[name]}
                fullWidth 
                value={value || formik.values[name] || ""}
                label={label ?? name.toUpperCase()}
                name={name}
                onChange={formik.handleChange || ""}
            />
        </WithHelpLayout>
    )
}
