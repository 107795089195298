import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import useEffectAsync from '../lib/helper/useEffectAsync';
import { httpClient } from '../lib/HttpClient';
import WithHelpLayout from './Common/WithHelpLayout';

const SelectField = ({
    name,
    formik,
    margin,
    variant,
    label,
    multiple,
    value,
    emptyValue = 0,
    onChange,
    endpoint,
    data,
    info,
    keyGetter = (item) => item.id,
    titleGetter = (item) => item.name,
}) => {
    const [items, setItems] = useState(data);

    useEffectAsync(async () => {
        if (endpoint) {
            const response = await httpClient.Get(endpoint);
            if (response) {
                setItems(response);
            }
        }
    }, [endpoint]);

    if (items) {
        return (
            <WithHelpLayout help={info}>
                <FormControl fullWidth margin={margin} >
                    <InputLabel shrink={!!value || !!formik.values[name]}>{label}</InputLabel>
                    <Select
                        error={formik.errors[name]}
                        multiple={multiple}
                        value={value || formik.values[name] || ""}
                        label={label}
                        name={name}
                        onChange={formik.handleChange || ""}
                    >
                        {!multiple && emptyValue !== null
                            && <MenuItem value={emptyValue}>&nbsp;</MenuItem>}
                        {items.map((item) => (
                            <MenuItem key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item)}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error >
                        {formik.errors[name]}
                    </FormHelperText>
                </FormControl>
            </WithHelpLayout>
        );
    }
    return null;
};

export default SelectField;
