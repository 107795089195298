import useEffectAsync from "../lib/helper/useEffectAsync"
import nopcommerce from '../assets/images/nopcommerce.png';
import wordpress from '../assets/images/wordpress.png';
import woo_commerce from '../assets/images/woo_commerce.png';
import Header from "../Pages/Header";

export default function Plugins() {
    //  const [items, setItems] = useState([])

    useEffectAsync(async () => {
        // var payments = await httpClient.Get('/api/payment');
        // setItems(payments);
    }, [])

    return (
        <div>
            <Header />
            <section className="plugins-page">
                <div className="container">
                    <div className="card border-radius border-0 pt-5">
                        <div className="card-body p-5">
                            <h2 className="title mb-4">CaraWay Plugins </h2>
                            <div className="description">Connect your site to the digital currency payment gateway with CaraWay payment plugins. You can easily equip your site with all kinds of payment gateways required in WordPress and NopCommerce by using CaraWay plugins. </div>
                            <div className="row align-items-center">
                                <div className="col-xl-4 col-12">
                                    <div className="card border-0 plugins-card">
                                        <div className="card-body">
                                            <div className="row align-items-center justify-content-center" style={{ height: '100%' }}>
                                                <div className="mb-3 mb-md-0 col">
                                                    <img src={nopcommerce} alt="nopcommerce" />
                                                </div>
                                                <div className="col-md-auto col-12">
                                                    <a download href="/plugins/caraway_payment_nc.zip" target="_self" className="text-primary link-font"> Download </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-12">
                                    <div className="card border-0 plugins-card">
                                        <div className="card-body">
                                            <div className="row align-items-center justify-content-center" style={{ height: '100%' }}>
                                                <div className="mb-3 mb-md-0 col">
                                                    <img src={wordpress} alt="wordpress" />
                                                </div>
                                                <div className="col-md-auto col-12">
                                                    <a download href="/plugins/caraway_payment_wc.zip" target="_self" className="text-primary link-font"> Download </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-12">
                                    <div className="card border-0 plugins-card">
                                        <div className="card-body">
                                            <div className="row align-items-center justify-content-center" style={{ height: '100%' }}>
                                                <div className="mb-3 mb-md-0 col">
                                                    <img src={woo_commerce} alt="woo_commerce" />
                                                </div>
                                                <div className="col-md-auto col-12">
                                                    <a download href="/plugins/caraway_payment_wc.zip" target="_self" className="text-primary link-font"> Download </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}
