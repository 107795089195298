import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

import RemoveDialog from './RemoveDialog';
import { Fab, Menu, MenuItem } from '@mui/material';
import t from '../lib/localization';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    
  },
}));

const EditCollectionView = ({
  content, editPath, endpoint, disableAdd, menuItems

}) => {

  const classes = useStyles();
  const history = useHistory();

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const adminEnabled = useSelector((state) => state.session.user && state.session.user.administrator);

  const menuShow = (anchorId, item) => {
    setSelectedAnchorEl(anchorId);
    setSelectedItem(item);
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  };

  const handleEdit = () => {
    history.push(`${editPath}/${selectedItem.id}`);
    menuHide();
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  };

  const Content = content;

  return (
    <>
      <Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} />
      {!disableAdd
        && (
          <Fab size="large" color="primary" className={classes.fab} onClick={handleAdd}>
            <AddIcon />
          </Fab>
        )}
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
        {editPath && <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>}
        <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
        {
          menuItems && menuItems(selectedItem, menuHide)
        }
      </Menu>
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedItem?.id} onResult={handleRemoveResult} />
    </>
  );
};

export default EditCollectionView;
