import { Button, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import WithHelpLayout from "../../components/Common/WithHelpLayout";
import TextInput from "../../components/TextInput";
import useEffectAsync from "../../lib/helper/useEffectAsync";
import { httpClient } from "../../lib/HttpClient";
import t, { languageList } from "../../lib/localization";
import * as Yup from 'yup'
import { useFormik } from "formik";
import SelectField from "../../components/SelectInput";


export default function PaymentSettings() {

    var schema = Yup.object({
        paymentSettings: Yup.object({
            paymentApprovalMargin: Yup.number().min(0).required(t("shared.mandatory"))
        })
    })

    var formik = useFormik({
        validationSchema: schema,
        initialErrors: false,
        initialValues: {
            paymentSettings: {
                paymentApprovalMargin: '',
                paymentPageDefaultLanguage:'en'
            }
        }
    })

    console.log('formik: ', formik.values);
    useEffectAsync(async () => {
        try {
            var response = await httpClient.Get("api/settings");

            formik.setValues(response);
        } catch (error) {
            toast.error(error.toString())
        }
    }, [])

    const handleSubmit = async () => {
        try {
            var response = await httpClient.Put("api/settings", formik.values);
            toast.success(t('settings.saved'))
        } catch (error) {
            toast.error(error.toString())
        }
    }

    return (
        <>
            <Typography variant="h4">
                {t("settings.paymentSettings")}
            </Typography>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs={12} md={4}>
                    <WithHelpLayout help={t("settings.paymentApprovalMargin.hint")}>
                        <TextInput type='number' formik={formik}
                            name='paymentSettings.paymentApprovalMargin'
                            error={formik.errors?.paymentSettings?.paymentApprovalMargin}
                            value={formik.values.paymentSettings.paymentApprovalMargin}
                            label={t("settings.paymentApprovalMargin")} />
                    </WithHelpLayout>
                </Grid>
                <Grid item xs={12} md={4}>
                        <SelectField
                            info={t("paymentSettings.defaultLanguage")}
                            margin="normal"
                            formik={formik}
                            data={languageList}
                            emptyValue={null}
                            value={formik.values?.paymentSettings?.paymentPageDefaultLanguage || ""}
                            label={t("paymentSettings.paymentPageDefaultLanguage")}
                            name="paymentSettings.paymentPageDefaultLanguage"
                            variant="filled"
                            keyGetter={i=>i.code}
                            titleGetter={i=>i.name}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button onClick={handleSubmit} disabled={!formik.isValid} variant="contained" >{t('Save')} </Button>
                </Grid>
            </Grid>
        </>
    )
}