import React from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import t from '../lib/localization';

const WithdrawDialog = ({
  open, endpoint, itemId, onResult,
}) => {
  const handleWithdraw = async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, { method: 'POST' });
    if (response.ok) {
      onResult(true);
    } else {
      toast.error(t("account.failedWithdraw"))
      onResult(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => { onResult(false); }}
    >
      <DialogContent>
        <DialogContentText>{t('sharedWithdrawConfirm')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleWithdraw}>{t('sharedWithdraw')}</Button>
        <Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithdrawDialog;
