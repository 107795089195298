import { CardContent, Card, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup'
import EditViewLayout from "../components/EditViewLayout";
import TextInput from "../components/TextInput";
import t from "../lib/localization";

export default function TokenNew() {

    var schema = Yup.object({
        name: Yup.string().required(t("formik.mandatory")).max(500, t("formik.maxLength", 500)),
        symbol: Yup.string().required(t("formik.mandatory")),
        systemName: Yup.string().required(t("formik.mandatory"))
    })

    var formik = useFormik({
        validationSchema: schema,
        initialErrors: false,
        initialValues: {}
    })

    return (
        <>
            <EditViewLayout endpoint="token" formik={formik} >
                <Card elevation={12}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextInput formik={formik} name="name" />
                            </Grid>
                            <Grid item>
                                <TextInput formik={formik} name="symbol" />
                            </Grid>
                            <Grid item>
                                <TextInput formik={formik} name="systemName" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </EditViewLayout>
        </>
    )
}